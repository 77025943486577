import { MDXProvider } from "@mdx-js/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "jotai";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Poppins, Roboto_Mono as RobotoMono } from "next/font/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MDXComponents } from "@/components/shared/ui/mdx";
import { authStore } from "@/stores/auth.store";
import "@/styles/globals.css";

const Layout = dynamic(() => import("@/components/shared/Layouts/Layout"), { ssr: false });

const poppins = Poppins({
	subsets: ["latin"],
	weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const robotoMono = RobotoMono({
	subsets: ["latin"],
	weight: ["100", "200", "300", "400", "500", "600", "700"],
});

const GlobalStyles = () => (
	<style jsx global>
		{`
			:root {
				--robotoMono-font: ${robotoMono.style.fontFamily};
				--poppins-font: ${poppins.style.fontFamily};
			}
		`}
	</style>
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
		mutations: {
			retry: 1,
		},
	},
});

export default function App({ Component, pageProps }: AppProps) {
	return (
		<>
			<Provider store={authStore}>
				<QueryClientProvider client={queryClient}>
					<MDXProvider components={MDXComponents}>
						<Layout>
							<Component {...pageProps} />
						</Layout>
					</MDXProvider>
					{process.env.NODE_ENV === "development" && (
						<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
					)}

					<ToastContainer />
					<GlobalStyles />
				</QueryClientProvider>
			</Provider>
		</>
	);
}
