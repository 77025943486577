const { createEnv } = require("@t3-oss/env-nextjs");
const z = require("zod");

module.exports.env = createEnv({
	server: {
		NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.union([
			z.literal("local"),
			z.literal("development"),
			z.literal("staging"),
			z.literal("production"),
			z.literal("beta"),
		]),
	},
	client: {
		NEXT_PUBLIC_API_BASE_URL: z.string().min(1, "NEXT_PUBLIC_API_BASE_URL is required"),
		NEXT_PUBLIC_FILE_DOWNLOAD_BASE_URL: z.string().min(1, "NEXT_PUBLIC_FILE_DOWNLOAD_BASE_URL is required"),
		NEXT_PUBLIC_DEFAULT_ROUND_LENGTH_IN_DAYS: z
			.string()
			.min(1, "NEXT_PUBLIC_FILE_DOWNLOAD_BASE_URL is required")
			.transform((v) => parseInt(v)),
		NEXT_PUBLIC_MIN_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL: z
			.string()
			.min(1, "NEXT_PUBLIC_MIN_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL is required")
			.transform((v) => parseInt(v)),
		NEXT_PUBLIC_MAX_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL: z
			.string()
			.min(1, "NEXT_PUBLIC_MAX_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL is required")
			.transform((v) => parseInt(v)),
		NEXT_PUBLIC_NUMBER_OF_ROUNDS: z
			.string()
			.min(1, "NEXT_PUBLIC_NUMBER_OF_ROUNDS is required")
			.transform((v) => parseInt(v)),
		NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.union([
			z.literal("local"),
			z.literal("development"),
			z.literal("staging"),
			z.literal("production"),
			z.literal("beta"),
		]),
	},
	runtimeEnv: {
		NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
		NEXT_PUBLIC_FILE_DOWNLOAD_BASE_URL: process.env.NEXT_PUBLIC_FILE_DOWNLOAD_BASE_URL,
		NEXT_PUBLIC_DEFAULT_ROUND_LENGTH_IN_DAYS: process.env.NEXT_PUBLIC_DEFAULT_ROUND_LENGTH_IN_DAYS,
		NEXT_PUBLIC_MIN_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL:
			process.env.NEXT_PUBLIC_MIN_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL,
		NEXT_PUBLIC_MAX_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL:
			process.env.NEXT_PUBLIC_MAX_REVIEWER_COUNT_FOR_METHODOLOGY_APPROVAL,
		NEXT_PUBLIC_NUMBER_OF_ROUNDS: process.env.NEXT_PUBLIC_NUMBER_OF_ROUNDS,
		NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
	},
});
